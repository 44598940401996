import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FaShoppingBag } from 'react-icons/fa';
import CartItem from '../CartItem';
import CheckoutTab from '@/components/global/checkout-tab';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRelatedCartItems,
  updateCartModalState,
} from '@/redux/slices/cart.slice';
import { GetCartItems } from '@/services/cart.api';
import RelatedCartItem from '../RelatedCartItem';
import AppStorage, {
  getRelatedProducts,
  mapItemsByCategory,
} from '@/utils/helpers';
import { GetOutletMenusForCustomer } from '@/services/menu.api';

type CartModalProps = {
  open: boolean;
  closeModal: () => void;
};

export default function ModalCart({ open, closeModal }: CartModalProps) {
  const { cart, relatedCartItems } = useSelector((state: any) => state.cart);
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const chosenOutlet = JSON.parse(AppStorage.get('outlet') || '{}');
  const dispatch = useDispatch();

  async function fetchMenuContent(id: string) {
    const res = await GetOutletMenusForCustomer(id);
    const categorized = mapItemsByCategory(res);
    if (cart.length) {
      let excludeIds: string[] = [];
      const categories: string[] = [];

      cart.forEach((product: any) => {
        excludeIds.push(product.id);
        if (
          product.cartData &&
          Array.isArray(product.cartData.relatedCategories)
        ) {
          categories.push(...product.cartData.relatedCategories);
        }
      });

      const allRelatedProducts = getRelatedProducts(
        categorized.all,
        categories,
        excludeIds
      ).filter((item: any) => item.is_available);

      const randomIndex = Math.floor(Math.random() * 1000) / 1000;
      const startIndex = Math.floor(
        randomIndex * (allRelatedProducts.length - 2)
      );
      const related = allRelatedProducts.slice(startIndex, startIndex + 3);
      dispatch(setRelatedCartItems(related));
    }
  }

  useEffect(() => {
    const totalPrice: number = cart?.reduce(
      (total: number, item: any) =>
        total + item?.cartData?.price * item.quantity,
      0
    );
    setCartTotalPrice(totalPrice);
  }, [cart]);

  useEffect(() => {
    GetCartItems().catch(() => {});
  }, []);

  useEffect(() => {
    if (chosenOutlet._id) {
      fetchMenuContent(chosenOutlet._id);
    }
  }, [cart]);

  useEffect(() => {
    if (cart.length === 0) {
      AppStorage.remove('coupon_type');
      AppStorage.remove('coupon_rate');
      AppStorage.remove('coupon_code');
      AppStorage.remove('couponPrice');
    }
  }, [cart]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          closeModal();
          dispatch(updateCartModalState(false));
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <Dialog.Panel className="relative w-[90%] lg:w-[80%] xl:w-[60%] max-w-4xl transform overflow-y-auto rounded-xl bg-white shadow-2xl transition-all">
                <div className="px-6 py-4 border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <FaShoppingBag className="w-6 h-6 text-primary-400" />
                      <Dialog.Title className="text-xl font-poppinsBold text-gray-900">
                        {cart?.length} item{cart?.length > 1 && 's'} - ₦
                        {cartTotalPrice.toLocaleString()}
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        closeModal();
                        dispatch(updateCartModalState(false));
                      }}
                      className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                    >
                      <XMarkIcon className="w-6 h-6 text-gray-500" />
                    </button>
                  </div>
                </div>

                {cart?.length > 0 ? (
                  <>
                    <div className="p-6 max-h-[60vh] overflow-y-auto">
                      <div className="space-y-3">
                        {cart.map((item: any, index: number) => (
                          <div
                            className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
                            key={index}
                          >
                            <CartItem cart={item} />
                          </div>
                        ))}
                      </div>

                      {relatedCartItems.length > 0 && (
                        <div className="mt-8">
                          <h3 className="text-lg font-semibold text-gray-800 text-center mb-6">
                            You can also add...
                          </h3>
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {relatedCartItems.map(
                              (cartItem: any, index: number) => (
                                <div
                                  className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors"
                                  key={cartItem.id || index}
                                >
                                  <RelatedCartItem cartItem={cartItem} />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="border-t border-gray-100 bg-gray-50 p-6">
                      <CheckoutTab
                        closeModal={() => {
                          closeModal();
                          dispatch(updateCartModalState(false));
                        }}
                        cartItem={cart}
                        price={cartTotalPrice}
                        setCartTotalPrice={setCartTotalPrice}
                      />
                    </div>
                  </>
                ) : (
                  <div className="p-12 text-center">
                    <FaShoppingBag className="w-16 h-16 mx-auto text-gray-400 mb-4" />
                    <h4 className="text-xl font-rakkas mb-4">
                      Your cart is empty
                    </h4>
                    <a href="/menu">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-lg px-6 py-3 bg-primary-400 text-white font-medium hover:bg-primary-500 transition-colors focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                      >
                        Browse Menu
                      </button>
                    </a>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
